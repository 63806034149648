:root {
  // Colors
  --black-main: #000;
  --white-main: #fff;
  --beige-main: #f6f6f3;
  --gray-pale: #f1f1f1;
  --gray-dark: #d9d9d9;
  --gray-opacity: rgba(217, 217, 217, 0.5);
  --gray-darker: #8e8e8e;
  --purple-light: #a790ac;
  --purple-middle: #472f45;
  --purple-dark: #2f1f2e;
  --purple-highlight: #594c58;
  --red-pale: #fdedeb;
  --red-light: #f4c6be;
  --red-main: #e94f37;
  --red-middle: #c9361f;
  --red-dark: #b2331b;
  --red-opacity: rgba(233, 79, 55, 0.8);
  --green-pale: #e6f5f0;
  --green-main: #209d7f;
  --green-dark: #146350;
  --orange-pale: #fff1e7;
  --yellow-opacity: rgba(255, 168, 0, 0.2);
  --orange-main: #ff6c00;
  --avatar-background: #fadeb7;
  --avatar-text: #6b5228;

  // Typography
  --font-family: 'Montserrat', sans-serif;
  --icon-font-family: 'Font Awesome 6 Free';
  --heading-xlarge-font-size: 64px;
  --heading-large-font-size: 48px;
  --heading-large-line-height: 58px;
  --heading-medium-font-size: 24px;
  --heading-medium-line-height: 34px;
  --heading-small-font-size: 20px;
  --heading-small-line-height: 24px;
  --body-font-size: 22px;
  --body-line-height: 33px;
  --medium-font-size: 16px;
  --medium-line-height: 24px;
  --small-font-size: 14px;
  --small-line-height: 21px;
  --xsmall-font-size: 12px;
  --xsmall-line-height: 18px;
  --xxsmall-font-size: 10px;

  // Box shadows
  --box-shadow-red: 0 0 4px 1px var(--red-light);
  --box-shadow-red-inset: inset 0 0 4px 1px var(--red-light);
  --box-shadow-red-no-blur: 0 0 0 1px var(--red-main);
  --box-shadow-gray-no-blur: 0 0 0 1px var(--gray-darker);
  --box-shadow-black: 0 4px 32px 0 rgba(0, 0, 0, 0.25);

  // Spaces
  --large-padding: 62px;
  --big-padding: 40px;
  --default-padding: 24px;
  --medium-padding: 16px;
  --small-padding: 12px;
  --xsmall-padding: 8px;
  --xxsmall-padding: 4px;

  // Borders
  --big-border-radius: 30px;
  --default-border-radius: 25px;
  --medium-border-radius: 20px;
  --small-border-radius: 15px;
  --xsmall-border-radius: 10px;
  --xxsmall-border-radius: 5px;
  --xxxsmall-border-radius: 4px;

  // Components
  --top-nav-height: 70px;

  // Buttons
  --default-input-height: 50px;
  --small-button-height: 28px;
  --medium-button-height: 36px;
  --default-textarea-height: 90px;

  // Widths
  --max-dashboard-width: 1660px;
}
